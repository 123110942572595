body {
    overflow-x: hidden;
}

.nav-bottom-shadow {
    /* border-bottom: .5px solid #F0F0F0; */
}

.embedded-link {
    font-weight: 500;
}

.highlight-on-hover {
	transition: transform 0.5s;
	position: relative;
	-webkit-transition: transform 0.5s;
	-moz-transition: transform 0.5s;
	-ms-transition: transform 0.5s;
	-o-transition: transform 0.5s;
}

.highlight-on-hover:hover {
	box-shadow: 0 20px 70px 5px rgba(118, 172, 129, 0.317);
	transform: translateY(-5px);
	text-decoration: none;
	transition: transform 0.5s;
}

.circledItem {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 9px;
    background: #fff;
    border: 2px solid #D3D3D3;
    text-align: center;
    font: 25px Arial, sans-serif;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin: -0.5% 0 0 2%;
}


.circledItem-home {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 30px;
    background: #fff;
    border: 2px solid #D3D3D3;
    text-align: center;
    font: 32px Arial, sans-serif;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin: 3% auto;
}

