#page-1 h1 { 
    color: grey !important;
}

/*---LAPTOPS AND ABOVE---*/

/*-- specific for this project's <h1>--*/

@media screen and (max-width: 1122px) and (-webkit-min-device-pixel-ratio: 1) {
   
}


@media screen and (max-width: 1072px) and (-webkit-min-device-pixel-ratio: 1) {
  
    
}


@media screen and (max-width: 992px) and (-webkit-min-device-pixel-ratio: 1) {
   
    
}

@media screen and (max-width: 870px) and (-webkit-min-device-pixel-ratio: 1) {}
@media screen and (max-width: 770px) and (-webkit-min-device-pixel-ratio: 1) {}
@media screen and (max-width: 657px) and (-webkit-min-device-pixel-ratio: 1) {}


/*iPhone 5*/
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    
   
}

/*Galaxy 5s*/
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
   
}

/*Samsung Galaxy Note 3 Media Queries (In terms of Mobile only)*/

@media only screen and (min-width: 360px) and (max-width: 767px) {
   

}

/* Galaxy Note 9 */

@media only screen and (min-width: 360px) and (max-width: 740px) {
   
}

/*iPhone XR*/
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    
   
}

/*iPhone 6/7/8*/
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
	
}

/*iPhone 6+/7+/8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
	 
  
}

/*iPhone X*/
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
	
}

/*iPhone X & XS*/
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
   
}

/* iphone XS Max */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 3) {

}

/*Galaxy 5s*/
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {

}

/*/////////Other androids///////////*/

/*Samsung Galaxy S9 Media Queries (In terms of Mobile only)*/

@media only screen and (min-width: 360px) and (max-width: 767px) {
	
}


/* My media queries for larger displays */

@media screen and (min-width: 1122px) and (max-width:1550px) and (-webkit-min-device-pixel-ratio: 1) {

}

@media screen and (min-width: 1551px) and (max-width:2000px) and (-webkit-min-device-pixel-ratio: 1) {
    
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {


}

/* ----------- Wide Screens ----------- */

@media only screen and (min-width: 1224px) {
}

@media only screen and (min-width: 1824px) {
}

@media (min-width: 2000) {
}


