:root {
  /* font-size: 16px; */
   font-family: 'Open Sans';
   --text-primary: #b6b6b6;
   --text-secondary: #ececec;
   --bg-primary: #23232e;
   --bg-secondary: #141418;
   --transition-speed: 600ms;
 }


 
 .vertical-nav-link.home, 
 .vertical-nav-link.glossary,
 .vertical-nav-item.about,
 .vertical-nav-item.university {
   text-decoration: none;
 }

 .vertical-nav-link.home:hover, 
 .vertical-nav-link.glossary:hover {
   color: white !important;
}

.vertical-navbar {
   z-index: 2147483647 !important;
 }
 
 /*
 body {
   color: black;
   background-color: white;
   margin: 0;
   padding: 0;
   color: yellow;
 }
 
 body::-webkit-scrollbar {
   width: 0.25rem;
 }
 
 body::-webkit-scrollbar-track {
   background: #1e1e24;
 }
 
 body::-webkit-scrollbar-thumb {
   background: #6649b8;
 } 
 */
 
 main {
   margin-left: 5rem;
   padding: 1rem;
 }
 
 .vertical-navbar {
   position: fixed;
   background-color: var(--bg-primary);
   transition: width 600ms ease;
   overflow: scroll;
 }
 
 .vertical-navbar-nav {
   list-style: none;
   padding: 0;
   margin: 0;
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 100%;
 }
 
 .vertical-nav-item {
   width: 100%;
 }
 
 .vertical-nav-item:last-child {
   margin-top: auto;
 }
 
 .vertical-nav-link {
   display: flex;
   align-items: center;
   height: 5rem;
   color: var(--text-primary);
   text-decoration: none;
   filter: grayscale(100%) opacity(0.7);
   transition: var(--transition-speed);
 }
 
 .vertical-nav-link:hover {
   filter: grayscale(0%) opacity(1);
   background: var(--bg-secondary);
   color: var(--text-secondary);
 }
 
 .link-text {
   display: none;
   margin-left: 1rem;
 }
 
 .vertical-nav-link svg {
   width: 2rem;
   min-width: 2rem;
   margin: 0 1.5rem;
 }
 
 .fa-primary {
   color: white;
 }
 
 .fa-secondary {
   color: grey;
 }
 
 .fa-primary,
 .fa-secondary {
   transition: var(--transition-speed);
 }
 
 .logo {
   font-weight: bold;
   text-transform: uppercase;
   margin-bottom: 1rem;
   text-align: center;
   color: var(--text-secondary);
   background: var(--bg-secondary);
   font-size: 1.5rem;
   letter-spacing: 0.3ch;
   width: 100%;
 }
 
 .logo svg {
   transform: rotate(0deg);
   transition: var(--transition-speed);
 }
 
 .logo-text
 {
   display: inline;
   position: absolute;
   left: -999px;
   transition: var(--transition-speed);
 }
 
 .vertical-navbar:hover .logo svg {
   transform: rotate(-180deg);
   -webkit-transform: rotate(-180deg);
   -moz-transform: rotate(-180deg);
   -ms-transform: rotate(-180deg);
   -o-transform: rotate(-180deg);
}
 
 /* Small screens */
 @media only screen and (max-width: 600px) {
   .vertical-navbar {
     bottom: 0;
     width: 100vw;
     height: 5rem;
   }
 
   .logo {
     display: none;
   }
 
   .vertical-navbar-nav {
     flex-direction: row;
   }
 
   .vertical-nav-link {
     justify-content: center;
   }
 
   main {
     margin: 0;
   }
 }
 
 /* Large screens */
 @media only screen and (min-width: 600px) {
   .vertical-navbar {
     top: 0;
     width: 5rem;
     height: 100vh;
   }
 
   .vertical-navbar:hover {
     width: 16rem;
   }
 
   .vertical-navbar:hover .link-text {
     display: inline;
   }
 
   .vertical-navbar:hover .logo svg
   {
     margin-left: 11rem;
   }
 
   .vertical-navbar:hover .logo-text
   {
     left: 0px;
   }
 }
 
 .dark {
   --text-primary: #b6b6b6;
   --text-secondary: #ececec;
   --bg-primary: #23232e;
   --bg-secondary: #141418;
 }
 
 .light {
   --text-primary: #1f1f1f;
   --text-secondary: #000000;
   --bg-primary: #ffffff;
   --bg-secondary: #e4e4e4;
 }
 
 .solar {
   --text-primary: #576e75;
   --text-secondary: #35535c;
   --bg-primary: #fdf6e3;
   --bg-secondary: #f5e5b8;
 }
 
 .theme-icon {
   display: none;
 }
 
 .dark #darkIcon {
   display: block;
 }
 
 .light #lightIcon {
   display: block;
 }
 
 .solar #solarIcon {
   display: block;
 }
